<template>
  <PromiseContainer :promises.sync="promises">
    <overview :items="getProjects" :headers="headers" :filters="filters" header-text="Projecten"
      add-btn-text="Nieuw project" :add-item-component="pushToCreateRoute" :detail-page-function="detailPageFunction">
      <template v-slot:item.status="{ item }">
        <v-chip :color="item.active ? 'green' : 'red'" label>
          {{ item.status }}
        </v-chip>
      </template>
    </overview>
  </PromiseContainer>
</template>

<script>
import Overview from "@/components/shared/Overview";
import RouteNames from "@/router/RouteNames";
import PromiseContainer from "@/components/shared/PromiseContainer.vue"

export default {
  name: "ProjectOverview.vue",
  components: {
    PromiseContainer,
    Overview
  },
  data() {
    return {
      headers: [
        {
          text: "Naam",
          value: 'projectName',
          cellClass: 'notranslate'
        },
        {
          text: "Klant",
          value: 'clientName',
          cellClass: 'notranslate'
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      filters: [
        {
          title: "Status",
          options: [
            {
              id: "project#status#active",
              label: "Actief",
              active: false,
              predicate: (project) => project.active,
            },
            {
              id: "project#status#inactive",
              label: "Inactief",
              active: false,
              predicate: (project) => !project.active,
            }
          ]
        }
      ],
      // TODO should be a fetch of the project only
      promises: [this.$store.dispatch("clientsModule/fetchNotArchivedClients").then(data => this.clients = data)],
      clients: [],
      detailPageFunction: (project) => {
        return {
          name: RouteNames.PROJECT_DETAIL,
          params: {
            projectId: project.id,
            clientId: project.clientId
          }
        }
      },
    }
  },
  methods: {
    pushToCreateRoute() {
      this.$router.push({ name: RouteNames.PROJECT_CREATE, params: { clientId: this.clientId } })
    },
  },
  computed: {
    getProjects() {
      if (!this.clients) {
        return [];
      }
      return this.clients.flatMap(client =>
        client.projects ? client.projects.map(project => ({
          ...project,
          status: project.active ? 'actief' : 'inactief',
          clientId: client.id,
          clientName: client.clientName
        })) : undefined
      ).filter(e => e != undefined)
    }
  }
}
</script>